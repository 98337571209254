import React from "react"
import styled from "styled-components"

import { Footer } from "src/components/composite/Footer"
import { Header } from "src/components/composite/Header"
import { LayoutDefault } from "src/components/layouts/LayoutDefault"
import { Button } from "src/components/ui/Button"

const Page404 = () => {
  return (
    <LayoutDefault>
      <Header floating />
      <Wrapper>
        <div css="margin-bottom: 2rem;">Page not found</div>
        <Button to="/">VISIT HOME PAGE</Button>
      </Wrapper>
      <Footer />
    </LayoutDefault>
  )
}

export default Page404

const Wrapper = styled.div`
  min-height: calc(100vh - 147px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-size: 40px;
`

import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { MEDIA_QUERIES } from "src/styles/mediaQueries"
import { validateInternalLink } from "src/utils/validateInternalLink"

interface Props {
  to?: string
  onClick?: () => void
}

export const Button: React.FC<Props> = ({ to, children, onClick }) => {
  return (
    <StyledButtonLink
      as={!to ? "button" : undefined}
      to={to ? validateInternalLink(to) : ""}
      onClick={onClick}
    >
      {children}
    </StyledButtonLink>
  )
}

const StyledButtonLink = styled(Link)<{ to: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 70px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.sans};
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;

  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    min-width: 380px;
  }

  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.color.primary};
    color: #fff;
  }
`
